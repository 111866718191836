import React, { useEffect, useState } from "react";
import { Chain } from "@usedapp/core";
import chainList from "../config/chainList";
import useTokenQuery from "../hooks/useTokenQuery";
import {
  DEFAULT_CHAIN_ID,
  TEST_CHAIN_ID,
} from "../config/constants";
import useCrossChainList from "../hooks/useCrossChainList";
import { isNativeToken, sortTokenList } from "../utils/tokenUtils";
import useChainGasPrice, { IGasPriceOption } from "../hooks/useChainGasPrice";
import { BigNumber } from "ethers";

export interface IChain {
  chainId: number;
  chainKey: string;
  name: string;
  rpcUrl: string;
  multicall?: string;
  currency: string;
  weth: string;
  nativeTokenReserve: number;
  usd?: string;
  dexScreenerChainId: string;
  dexToolsChainId: string;
  image?: string;
  useDappChain?: Chain;
  explorerUrl: string | undefined;
}

export interface IToken {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  icon: string;
}

export interface IChainConfigContext {
  supportedChains: IChain[];
  supportedCrossChains: IChain[];
  supportedTokens: IToken[][] | undefined;
  gasPriceOptions: IGasPriceOption[] | undefined;
  maxFeePerGas: BigNumber | undefined;
  maxPriorityFeePerGas: BigNumber | undefined;
  getChainById: (chainId: number) => IChain | undefined;
  getChainNameById: (chainId: number) => string | undefined;
  isChainIdSupported: (chainId: number, crossChain?: boolean) => boolean;
  getTokensByChainId: (chainId: number) => Promise<IToken[]>;
  getTokenByAddress: (
    tokenAddress: string,
    chainId: number,
  ) => IToken | undefined;
  getTokenByTicker: (
    ticker: string,
    chainId: number,
  ) => IToken | undefined;
  getBridgeSupportedTokens: (
    chainIdFrom: number,
    chainIdTo: number,
  ) => IToken[] | undefined;
}

const ChainConfigContext = React.createContext<IChainConfigContext>(
  {} as IChainConfigContext,
);

const ChainConfigProvider = ({ children }) => {
  const { getTokenList } = useTokenQuery();
  const { getCrossChainList } = useCrossChainList();
  const { gasPriceOptions, maxFeePerGas, maxPriorityFeePerGas } = useChainGasPrice();

  const [supportedCrossChains, setSupportedCrossChains] = useState<IChain[]>(
    [],
  );
  const [supportedChains, setSupportedChains] = useState<IChain[]>(chainList);
  const [supportedTokens, setSupportedTokens] = useState<IToken[][]>();
  const [loading, setLoading] = useState(false);

  const getTokensByChainId = async (chainId: number) => {
    let _tokens: IToken[] = [];
    _tokens = supportedTokens?.[chainId]?.length
      ? supportedTokens?.[chainId]
      : ((await getTokenList(chainId)) as unknown as IToken[]);
    _tokens = sortTokenList(_tokens);
    let _supportedTokens = supportedTokens || [];
    _supportedTokens[chainId] = _tokens;
    setSupportedTokens(_supportedTokens);
    return _tokens;
  };

  const getTokenByAddress = (
    tokenAddress: string,
    chainId: number,
  ): IToken | undefined => {
    if (!!supportedTokens?.[chainId]?.length) {
      if (isNativeToken(tokenAddress)) {
        return supportedTokens?.[chainId]?.find(
          (e) => isNativeToken(e.address),
        );
      } else {
        return supportedTokens?.[chainId]?.find(
          (e) => e.address?.toLowerCase() == tokenAddress?.toLowerCase(),
        );
      }
    } else {
      if (!loading) {
        setLoading(true);
        getTokensByChainId(chainId).finally(() => {
          setLoading(false);
        });
      }
    }
  };

  const getTokenByTicker = (
    ticker: string,
    chainId: number,
  ): IToken | undefined => {
    if (!!supportedTokens?.[chainId]?.length) {
      return supportedTokens?.[chainId]?.find(
        (e) => ticker === "" 
          ? isNativeToken(e.address)
          : e.symbol?.toLowerCase() === ticker?.toLowerCase(),
      );
    } else {
      if (!loading) {
        setLoading(true);
        getTokensByChainId(chainId).finally(() => {
          setLoading(false);
        });
      }
    }
  };

  const getChainById = (chainId): IChain | undefined => {
    chainId = parseInt(`${chainId}`);
    return supportedChains.find((network) => network.chainId === chainId);
  };

  const getChainNameById = (chainId) => {
    return supportedChains.find(
      (network) => `${network.chainId}` === `${chainId}`,
    )?.name;
  };

  const isChainIdSupported = (chainId, crossChain = false) => {
    return !!(crossChain ? supportedCrossChains : supportedChains).find(
      (network) => `${network.chainId}` === `${chainId}`,
    )?.chainId;
  };

  const getBridgeSupportedTokens = (chainIdFrom: number, chainIdTo: number) => {
    if (!supportedTokens?.[chainIdFrom]?.length && !loading) {
      getTokensByChainId(chainIdFrom);
      return undefined;
    }
    if (!supportedTokens?.[chainIdTo]?.length && !loading) {
      getTokensByChainId(chainIdTo);
      return undefined;
    }
    let tokensFrom = supportedTokens?.[chainIdFrom];
    let tokensToSymbols = supportedTokens?.[chainIdTo].map((e) =>
      e.symbol.toLowerCase(),
    );
    tokensFrom = tokensFrom?.filter((e) =>
      tokensToSymbols?.includes(e.symbol.toLowerCase()),
    );
    return tokensFrom;
  };

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      await getTokensByChainId(DEFAULT_CHAIN_ID);
      setLoading(false);
    };

    initialize();

    getCrossChainList().then((chains: [{ chainId: number }]) => {
      const crossChainIds = chains.map((e) => e.chainId);
      setSupportedCrossChains(
        chainList.filter(
          (e) =>
            crossChainIds.includes(e.chainId) || e.chainId === TEST_CHAIN_ID,
        ),
      );
    });
  }, []);

  return (
    <ChainConfigContext.Provider
      value={{
        supportedChains,
        supportedCrossChains,
        supportedTokens,
        gasPriceOptions,
        maxFeePerGas,
        maxPriorityFeePerGas,
        getChainById,
        getChainNameById,
        getTokensByChainId,
        getTokenByAddress,
        getTokenByTicker,
        isChainIdSupported,
        getBridgeSupportedTokens,
      }}
    >
      {children}
    </ChainConfigContext.Provider>
  );
};

export { ChainConfigContext, ChainConfigProvider };
